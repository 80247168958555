import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withKeys as _withKeys, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "flex-grow-0 flex-shrink-0 icon-search mr-2.5",
  style: {"font-size":"24px","line-height":"24px"}
}
const _hoisted_2 = ["value", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex items-center px-5 h-11 rounded-3xl py-3 border main-blur text-primary-50 hover-animation hover:text-primary-100 hover:bg-solid", { 'border-primary-100': _ctx.focus, 'border-n3': !_ctx.focus }]),
    onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args)))
  }, [
    _withDirectives(_createElementVNode("i", _hoisted_1, null, 512), [
      [_vShow, !_ctx.focus]
    ]),
    _createElementVNode("input", {
      ref: "ipt",
      class: "h-full w-full flex-grow flex-shrink border-none outline-none bg-transparent text-white placeholder-customSet-4",
      type: "text",
      value: _ctx.search,
      placeholder: _ctx.placeholder,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:search', $event.target.value))),
      onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.$emit('finish')), ["enter"])),
      onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:focus', true))),
      onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('update:focus', false)))
    }, null, 40, _hoisted_2),
    (_ctx.showClose)
      ? (_openBlock(), _createElementBlock("i", {
          key: 0,
          class: _normalizeClass(["flex-grow-0 flex-shrink-0 font-normal icon-close ml-2.5 cursor-pointer", `${_ctx.iconColor}`]),
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('update:search', ''))),
          style: {"font-size":"20px","line-height":"20px"}
        }, null, 2))
      : _createCommentVNode("", true)
  ], 2))
}