
import { defineComponent, ref, onMounted, onBeforeUnmount } from "vue";

export default defineComponent({
  name: "Search",
  props: {
    placeholder: {
      type: String,
      default: "Search",
    },
    search: {
      type: String,
      default: "",
    },
    focus: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    iconColor: {
      type: String,
      default: "text-black-100",
    },
  },
  setup(props, { emit }) {
    const ipt = ref();
    const handleInput = () => {
      ipt.value.focus();
    };
    return {
      ipt,
      handleInput,
    };
  },
});
