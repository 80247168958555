
import {
  defineComponent,
  ref,
  watch,
  onMounted,
  onBeforeUnmount,
} from "vue";
import Loading from "@/components/Loading.vue";
import Search from "@/components/Search.vue";
import ListItems from "@/components/list/ListItems.vue";
import Toast from "@/components/toast/index";
import { useRoute } from "vue-router";
import { getCollectionDetail } from "@/apis/tracking";
import { itemDetailReq } from "@/apis";
import { decimalizeNumber, amountFormat } from "@/utils/numberUtils";
import { kmbNumber, getFloat } from "@/utils/formatNumber";
import { getLongStringShow, copyString } from "@/utils/formatString";
export default defineComponent({
  name: "CollectionDetail",
  components: {
    ListItems,
    Search,
    Loading,
  },
  setup() {
    const loading = ref(true);
    const route = useRoute();
    const state = ref();
    const pageNum = ref(0);
    const loadNext = ref();
    const showLoadMore = ref(true);
    const listLoading = ref(false);
    const total = ref(0);

    const hadFocus = ref(false);
    const search = ref("");

    let ob: any;
    let first = true;
    const currentOption = [
      {
        name: `${"Current Price: high to low"}`,
        id: "curPrice_desc",
        icon: "down",
      },
      {
        name: `${"Current Price: low to high"}`,
        id: "curPrice_asc",
        icon: "up",
      },
    ];
    const orderBy = ref(currentOption[0]);

    watch(orderBy, () => {
      pageNum.value = 0;
    });

    const handleCopyString = (string = "") => {
      copyString(string);
      Toast("Copy successfully");
    };

    const dataList: any = ref([]);
    let isFirst = true;
    const getCollectionDetailData = () => {
      getCollectionDetail({
        pageNum: pageNum.value,
        pageSize: 15,
        contract: route?.params?.contract,
      }).then((res) => {
        loading.value = false;
        if (res?.nextPageNum === -1) {
          showLoadMore.value = false;
        } else {
          showLoadMore.value = true;
          pageNum.value += 1;
        }
        if (isFirst) {
          state.value = res?.data || {};
          isFirst = false;
        }
        total.value = res?.totalSize;
        first = false;
        dataList.value = dataList.value.concat(res?.data?.items || []);
      });
    };
    getCollectionDetailData();

    const getItemDetail = async () => {
      const [err, res] = await itemDetailReq({
        contractAddr: route?.params?.contract + "",
        tokenId: search.value + "",
        language: "en",
        country: "",
        moreInCollection: 1,
      });
      listLoading.value = false;
      if (res?.code != 4001) {
        dataList.value = [res?.data || {}];
      } else {
        dataList.value = [];
      }
    };
    const handleSearch = () => {
      dataList.value = [];
      listLoading.value = true;
      if(search.value) {
        showLoadMore.value = false;
        first = true;
        getItemDetail();
      } else {
        dataList.value = state.value?.items || [];
        listLoading.value = false;
        showLoadMore.value = true;
        first = false;
      }
    };

    onMounted(() => {
      ob = new IntersectionObserver(
        (entries) => {
          if (entries[0].intersectionRatio === 0) return;
          if (first) {
            first = false;
            return;
          }
          console.log("loadmore");
          getCollectionDetailData();
        },
        {
          rootMargin: "200px",
          threshold: [0],
        }
      );
      ob.observe(loadNext.value as HTMLElement);
    });
    onBeforeUnmount(() => {
      ob.disconnect();
    });

    return {
      state,
      loading,
      kmbNumber,
      getFloat,
      decimalizeNumber,
      amountFormat,
      getLongStringShow,
      copyString,
      handleCopyString,
      showLoadMore,
      loadNext,
      listLoading,
      currentOption,
      orderBy,
      dataList,
      hadFocus,
      search,
      handleSearch,
      total,
    };
  },
});
